.gradient,
.btn,
.btn:focus,
.blockquote:after,
blockquote:after,
.vfbp-form .btn-primary,
.pnty-btn,
.instagram-item a:before {
    background-size: cover;
}

.colored-instagram {
    background-image: url('../../dist/images/fallback-instagram-ie.jpeg');
    background-size: cover;
}

.overlay:before {
    background-image: url('../../dist/images/fallback-gradient-ie.jpeg');
    background-size: cover;
}

.header-info__tabs__item--active a:after,
.header-info__tabs__item--active span:after {
    background-image: url('../../dist/images/fallback-gradient-ie.jpeg');
    background-size: cover;
}

.featured-jobs.offset-top {
    margin-top: 0;
}

.featured-jobs {
    height: auto;
}

.ticker {
    margin-left: 500px;
    width: 100%;
    width: auto;
}

.ticker-indicator:before {
    bottom: 0;
    top: 0;
}

.featured-jobs {
    padding-left: 0;
}

html.full-banner .featured-jobs {
    margin-bottom: 0;
    margin-top: 0;
    top: 0;
}

.outdated-ie {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-bottom: 0;
}

.loader {
    background-image: url('../images/loader.gif');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (min-width:911px) {
    html.full-banner .featured-jobs {
        margin-top: 0;
    }
}

.employee-button {
    z-index: 11;
}

.header-nav .menu-item-has-children {
    .sub-menu li:last-child:after {
        @media screen and (min-width: 768px) {
            background-image: url('../../dist/images/fallback-gradient-ie.jpeg');
            background-size: cover;
        }
    }
}


.flex-row {
    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }
}

.flex-row {
	display: block;
}

* html .flex-row {
    height: 1%;
}

.flex-col,
.flex-col-1,
.flex-col-10,
.flex-col-11,
.flex-col-12,
.flex-col-2,
.flex-col-3,
.flex-col-4,
.flex-col-5,
.flex-col-6,
.flex-col-7,
.flex-col-8,
.flex-col-9 {
    @media screen and (min-width: 992px) {
        float: left;
    }
}

.fifty-fifty {
	.flex-row {
		@media screen and (min-width: 992px) {
			font-size: 0;
		}
	}
	.col-image,
	.col-content {
		@media screen and (min-width: 992px) {
			font-size: inherit;
			float: none;
			display: inline-block;
			vertical-align: middle;
		}
	}
	.order-first{
		@media screen and (min-width: 992px) {
			font-size: inherit;
			float: left;
		}
	}
	.order-last{
		@media screen and (min-width: 992px) {
			font-size: inherit;
			float: right;
		}
	}
}

.three-col {
    .justify-content-center {
        text-align: center;
    }

    .flex-col-2 {
        @media screen and (min-width: 992px) {
            float: none;
            display: inline-block;
        }
    }
}

.header-box {
    .justify-content-center {
        text-align: center;
    }

    .header-box-header {
        @media screen and (min-width: 992px) {
            float: none;
            display: inline-block;
        }
    }
}

.tablist {
    .tablist-item {
        .item-container {
            display: block;

            &:after {
                visibility: hidden;
                display: block;
                font-size: 0;
                content: " ";
                clear: both;
                height: 0;
            }

            @media screen and (min-width: 768px) {
                min-height: initial;
                padding-top: 10px;
                padding-bottom: 10px;
			}
			@media screen and (min-width: 992px) {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            &.item-title {
                .info {
                    @media screen and (min-width: 768px) {
                        min-height: initial;
                    }
                }

                .image,
                .name,
                .info {
                    @media screen and (min-width: 768px) {
                        margin-top: 20px;
					}
					
					@media screen and (min-width: 992px) {
                        margin-top: 60px;
                    }
                }
            }
		}
		&.is-four {
			.item-container {
				.name {
					@media screen and (min-width: 768px) {
						max-width: 200px;
					}
					@media screen and (min-width: 992px) {
						max-width: 240px;
					}
					@media screen and (min-width: 1340px) {
						max-width: 480px;
					}
				}
			}		
		}
    }

    .image,
    .name,
    .info {
        @media screen and (min-width: 768px) {
            display: inline-block;
            vertical-align: middle;
            width: 100%;
        }
    }

    .info {
		@media screen and (min-width: 768px) {
            max-width: 300px;
        }
		@media screen and (min-width: 992px) {
            max-width: 440px;
        }
        @media screen and (min-width: 1340px) {
            max-width: 490px;
        }
	}
}

* html .item-container {
    height: 1%;
}